<template>
	<div class="project-container">
		<div class="project-image-container"></div>
		<img class="project-image" :src="topImage" />
		<div class="project-text">
			<h2>Restaurering</h2>
			<p>
				Visste du at vi i Norge er de som pusser opp aller mest? Velger du restaurering og oppussing gjennom Ellingsen Byggeservice hjelper vi deg å finne de gode og langsiktige løsningene, uten å gå på kompromiss av de estetiske. 
                Da kan du nyte hjemmet ditt i mange år fremover, uten å tenke på hva du gjerne skulle ha gjort annerledes og hva som må gjøres neste gang du skal pusse opp!
			</p>
			<p>
				Ta kontakt for en uforpliktende og hyggelig konsultasjon. Kontaktdetaljer finner du <a href="/kontakt">her</a>
			</p>
		</div>
	</div>
</template>

<script>
export default {
	props: ['topImage'],
}
</script>

<style scoped>
.project-image {
	width: 1000px;
	max-width: 70%;
	padding-top: 2%;
}

</style>
